import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import HorizontalAd from '@/components/ads/horizontalAd';

const SkeletonCard = () => {
  return (
    <TableRow>
      <TableCell className="text-center"><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell className="text-center"><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell className="text-center"><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell className="text-center"><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell className="text-center hidden md:table-cell"><Skeleton className="h-4 w-full" /></TableCell>
    </TableRow>
  );
};

export default function Status() {
  const [status, setStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [startNum, setStartNum] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page")) || 1;
    if (page < 1 || page > 5) {
      navigate("/status?page=1");
    } else {
      setCurrentPage(page);
      fetchStatus(page);
    }
  }, [location.search, navigate]);

  const fetchStatus = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(`https://codup.kr/api/status?page=${page}`);
      const data = await response.json();
      setStatus(data.status);
      setStartNum(data.start_num);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching status:", error);
      setLoading(false);
    }
  };

  const getUsernameColor = (ratingRank) => {
    if (ratingRank === 'N/A') return 'text-black';
    const rank = parseInt(ratingRank);
    if (rank <= 50) return 'text-red-500';
    if (rank <= 100) return 'text-blue-500';
    if (rank <= 150) return 'text-orange-500';
    return 'text-black';
  };

  return (
    <>
      <HorizontalAd />
      <div>
        <h1 className="text-4xl font-bold mb-4 mt-8 text-center">최근 기록</h1>
        <div className="flex justify-center">
          <div className="w-full max-w-4xl">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-center">번호</TableHead>
                  <TableHead className="text-center">코드업 ID</TableHead>
                  <TableHead className="text-center">추가 점수</TableHead>
                  <TableHead className="text-center">전날 맞은문제 수</TableHead>
                  <TableHead className="text-center hidden md:table-cell">레이팅</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {loading ? (
                  Array.from({ length: 10 }).map((_, index) => (
                    <SkeletonCard key={index} />
                  ))
                ) : status.length > 0 ? (
                  status.map((stat, index) => {
                    const globalIndex = startNum + index;
                    return (
                      <TableRow key={stat.user_id}>
                        <TableCell className="text-center">{globalIndex}</TableCell>
                        <TableCell className="text-center">
                          <a
                            href={`/profile/${stat.user_id}`}
                            className={`font-extrabold ${getUsernameColor(stat.rating_rank)}`}
                          >
                            {stat.user_id}
                          </a>
                        </TableCell>
                        <TableCell className="text-center text-red-500 font-bold">+ {stat.additional_score}</TableCell>
                        <TableCell className="text-center">{stat.daily_ac_count}</TableCell>
                        <TableCell className="text-center hidden md:table-cell">{stat.rating}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="5" className="text-center">표시할 데이터가 없습니다.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
        <div className="mt-4 flex justify-center mb-4">
          <Pagination>
            <PaginationContent>
              {Array.from({ length: 5 }, (_, index) => (
                <PaginationItem key={index}>
                  <PaginationLink
                    href={`/status?page=${index + 1}`}
                    isActive={currentPage === index + 1}
                  >
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}
            </PaginationContent>
          </Pagination>
        </div>
      </div>
    </>
  );
}