import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import Home from '@/pages/Home';
import Rating from '@/pages/Rating';
import RealRank from '@/pages/Real-rank';
import Profile from '@/pages/Profile';
import Faq from '@/pages/Faq';
import Status from '@/pages/Status';
import Climber from '@/pages/Climber';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rating" element={<Rating />} />
            <Route path="/real-rank" element={<RealRank />} />
            <Route path="/status" element={<Status />} />
            <Route path="/rating/climbers" element={<Climber />} />
            <Route path="/profile/:userId" element={<Profile />} />  
            <Route path="/faq" element={<Faq />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
