import React from "react";

const Footer = () => {
  return (
      <footer className="bg-gray-200 text-center p-4 mt-4">
        <p className="text-gray-800 text-m">코드업 전적분석 서비스, 코덥</p>
        <a href="mailto:codupkr@gmail.com" className="text-blue-600 hover:underline">
          codupkr@gmail.com
        </a>
      </footer>
  );
};

export default Footer;
