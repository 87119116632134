import React, { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Terminal } from "lucide-react";

export default function Home() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== '') {
      window.location.href = `/profile/${searchTerm}`;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-[80vh] bg-white">
      <div className="text-center px-4">
        <h1 className="text-8xl sm:text-9xl md:text-10xl lg:text-11xl xl:text-12xl font-bold mb-4 bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
          Codup
        </h1>
        <p className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl mb-8 text-gray-700">
          코드업 전적분석 서비스, 코덥
        </p>
        <div className="flex flex-col items-center w-full mb-12">
          <form className="w-full max-w-md" onSubmit={handleSearch}>
            <div className="relative mx-auto max-w-md">
              <Terminal className="absolute left-4 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <Input
                className="w-full pl-12 border-2 border-blue-500 focus:border-4 focus:outline-none focus:ring-0 placeholder-gray-400 text-gray-700 text-center text-lg py-3 rounded-full custom-input"
                type="search"
                value={searchTerm}
                placeholder="@Codeup ID"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
