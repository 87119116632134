import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import HorizontalAd from '@/components/ads/horizontalAd';

const SkeletonCard = () => {
  return (
    <TableRow>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
    </TableRow>
  );
};

export default function RealRank() {
  const [ranks, setRanks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [startRank, setStartRank] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page")) || 1;
    if (page < 1 || page > 10) {
      navigate("/real-rank?page=1");
    } else {
      setCurrentPage(page);
      fetchRanks(page);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchRanks = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(`https://codup.kr/api/real-rank?page=${page}`);
      const data = await response.json();
      if (data && Array.isArray(data.ranks)) {
        setRanks(data.ranks);
        setStartRank(data.start_rank);
      } else {
        setRanks([]);
        setStartRank(1);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching ranks:", error);
      setRanks([]);
      setStartRank(1);
      setLoading(false);
    }
  };

  const getUsernameColor = (ratingRank) => {
    if (ratingRank === 'N/A') return 'text-black';
    const rank = parseInt(ratingRank);
    if (rank <= 50) return 'text-red-500';
    if (rank <= 100) return 'text-blue-500';
    if (rank <= 150) return 'text-orange-500';
    return 'text-black';
  };

  const renderPagination = () => {
    if (isMobile) {
      return (
        <>
          <PaginationItem>
            <PaginationPrevious
              href={`/real-rank?page=${Math.max(1, currentPage - 1)}`}
            />
          </PaginationItem>
          {currentPage > 1 && (
            <PaginationItem>
              <PaginationLink href={`/real-rank?page=${currentPage - 1}`}>
                {currentPage - 1}
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationLink href={`/real-rank?page=${currentPage}`} isActive>
              {currentPage}
            </PaginationLink>
          </PaginationItem>
          {currentPage < 10 && (
            <PaginationItem>
              <PaginationLink href={`/real-rank?page=${currentPage + 1}`}>
                {currentPage + 1}
              </PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationNext
              href={`/real-rank?page=${Math.min(10, currentPage + 1)}`}
            />
          </PaginationItem>
        </>
      );
    } else {
      return Array.from({ length: 10 }, (_, index) => (
        <PaginationItem key={index}>
          <PaginationLink
            href={`/real-rank?page=${index + 1}`}
            isActive={currentPage === index + 1}
          >
            {index + 1}
          </PaginationLink>
        </PaginationItem>
      ));
    }
  };

  return (
    <>
    <HorizontalAd />
    <div>
      <h1 className="text-4xl font-bold mb-4 mt-8 text-center">리얼-랭크 순위표</h1>
      <div className="flex justify-center">
        <div className="w-full max-w-4xl">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>순위</TableHead>
                <TableHead>코드업 ID</TableHead>
                <TableHead>맞은문제 수</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <SkeletonCard key={index} />
                ))
              ) : (
                ranks.length > 0 ? (
                  ranks.map((rank, index) => {
                    const globalIndex = startRank + index;
                    return (
                      <TableRow key={rank.user_id}>
                        <TableCell>{globalIndex}</TableCell>
                        <TableCell>
                          <a
                            href={`/profile/${rank.user_id}`}
                            className={`font-extrabold ${getUsernameColor(rank.rating_rank)}`}
                          >
                            {rank.user_id}
                          </a>
                        </TableCell>
                        <TableCell>{rank.ac_count}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="3">표시할 데이터가 없습니다.</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="mt-4 flex justify-center mb-4">
        <Pagination>
          <PaginationContent>
            {renderPagination()}
          </PaginationContent>
        </Pagination>
      </div>
    </div>
    </>
  );
}