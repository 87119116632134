import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export default function Faq() {
  return (
    <>
      <div className="container mx-auto px-4 py-8 mb-4">
        <div className="flex flex-col md:flex-row items-center justify-between mb-8">
          <h1 className="text-4xl font-bold mb-4 md:mb-0">코덥 사용설명서</h1>
          <p className="text-xl md:text-2xl font-semibold text-center md:text-left">
            코드업에서 문제를 풀어 코덥에도 참여해보세요!
          </p>
        </div>

        <Accordion
          type="single"
          collapsible
          className="w-full border rounded-lg p-4 shadow-lg"
        >
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-xl font-bold">
              목차 1 - 코드업과 코덥
            </AccordionTrigger>
            <AccordionContent>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                <strong>지금은 정보과학의 시대이며 코딩의 시대입니다.</strong>
                <br />
                오늘날 정보과학은 중고교 교육과정에서 필수 과목이 되었고,
                <br />
                대학에서도 필수적으로 수강하도록 하는 과목이 되었습니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                <a
                  href="https://codeup.kr"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-gradient"
                >
                  코드업 온라인 저지(Codeup Online Judge) 사이트
                </a>
                는 <strong>프로그래밍을 공부하는 학생들에게 도움이 되기 위해 만들어진 사이트</strong>
                입니다.
                <br />
                온라인 환경에서 실시간으로 프로그래밍 문제를 풀이할 수 있도록 운영되고 있습니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                <strong>코덥 전적분석 사이트는 코드업을 지원해주는 사이트입니다.</strong>
                <br />
                코드업 이용자들이 즐겁게 프로그래밍을 학습할 수 있도록 돕고 있습니다.
                <br />
                여러 기능과 지표 제공을 통해 학습 동기 부여를 목표로 하고 있습니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                <strong>코덥은 코드업 사용자들에게 다양한 혜택을 제공합니다.</strong>
                <br />
                먼저, 레이팅 시스템을 통해 사용자들의 꾸준한 학습을 장려하고, 성취감을 느낄 수 있도록 도와줍니다.
                <br />
                또한, 리얼-랭크 순위표를 통해 다른 사용자들과의 경쟁을 통한 동기 부여를 제공합니다.
                <br />
                프로필 페이지에서는 문제 추천과 AI 기반 분석을 통한 사용자 맞춤형 피드백을 제공하여, 보다
                효율적인 학습을 돕습니다.
              </p>
              <p className="text-base md:text-lg leading-7 md:leading-8">
                코덥은 코드업 게시판 관리자로 활동하고 있는{" "}
                <a
                  href="https://codeup.kr/userinfo.php?user=kimgihong38"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-gradient"
                >
                  김시우
                </a>
                에 의해 개발되었습니다.
                <br />
                언제나 응원해 주시는{" "}
                <a
                  href="https://codeup.kr/userinfo.php?user=admin"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-gradient"
                >
                  코드업 운영자님
                </a>
                과 항상 도움 주시는 모든 분들에게 감사드립니다.
                <br />
                패치 노트는 <a
                  href="https://codeup.kr/d_thread.php?tid=14511"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-gradient"
                >
                  이곳에서
                </a> 확인하실 수 있습니다.
                <br />
                <br />
                코덥은 아직 완벽하지 않습니다.
                <br />
                이용자 여러분들의 의견과 제안이 필요합니다.
                <br />
                어떤 의견이든 괜찮습니다. 페이지 하단의 이메일로 보내주세요!
              </p>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-2">
            <AccordionTrigger className="text-xl font-bold">
              목차 2 - 레이팅
            </AccordionTrigger>
            <AccordionContent>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                코덥은 레이팅을 통해{" "}
                <strong>지금 열심히 학습하는 이용자를 나타내주는 지표를 제공</strong>
                하려 합니다.
                <br />
                시즌이 시작되면 <strong>기존에 풀이한 문제의 개수에 따라 배치 점수를 부여</strong>
                받습니다.
              </p>
              <ul className="list-disc ml-6 mb-6 text-base md:text-lg leading-7 md:leading-8">
                <li>
                  100문제 미만의 유저들은 <strong>0점</strong>,
                </li>
                <li>
                  800문제 이상의 유저들은 <strong>350점</strong>,
                </li>
                <li>
                  100문제 이상 800문제 미만의 유저들은 <strong>그 사이의 점수를 선형적으로 부여</strong>
                  합니다.
                </li>
              </ul>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                배치 레이팅은 프로필에서도 확인할 수 있습니다.
                <br />
                배치 레이팅 시스템은 최상위권 변별을 위해 향후 조정될 예정입니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                레이팅은 <strong>매일 한 번, 자정 이후에 갱신</strong>
                됩니다.
                <br />
                레이팅은 새롭게 풀이한 문제의 개수와 기존에 풀이한 문제의 개수를 모두 고려하여 점수가 추가됩니다.
                <br />
                <br />
                <strong>하루에 문제를 많이 풀이하면 그만큼 점수를 많이 받습니다.</strong>
                <br />
                그러나, <strong>6문제 이상부터는 가중치가 적용되어 문제 당 받는 점수가 감소</strong>
                합니다.
                <br />
                <strong>또한, 기존에 풀이한 문제의 수가 많을수록 새롭게 풀이한 문제 당 점수를 많이 받습니다.</strong>
                <br />
                <br />
                <strong>이벤트를 통해 풀이한 문제는 제출 기록이 공개되지 않습니다.</strong>
                <br />
                이벤트와는 별도로 제출해야 코덥에 반영됩니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                <strong>
                  2주 이상의 기간동안 '정확한 풀이'를 받지 못한 유저는 비활성 유저로 분류되어 레이팅 순위표에서
                  제외되고, 랭킹 산출에 포함되지 않습니다.
                </strong>
                <br />
                그러나 레이팅 점수는 유지됩니다.
                <br />
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                여기서의 '정확한 풀이'는 이미 풀이했던 문제를 다시 맞는 경우도 포함됩니다.
                <br />
              </p>
              <p className="text-base md:text-lg leading-7 md:leading-8">
                <strong>레이팅 점수가 150점을 초과하는 유저들에게는 비활성 페널티가 적용됩니다.</strong>
                <br />
                1달 이상의 기간동안 아무런 제출기록이 없을 경우, 50점이 감점됩니다.
                <br />
                그러나 페널티가 누적되어도 150점 미만으로 점수가 내려가진 않습니다.
                <br />
                페널티 적용은 매월 1일입니다.
              </p>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-3">
            <AccordionTrigger className="text-xl font-bold">
              목차 3 - 리얼-랭크
            </AccordionTrigger>
            <AccordionContent>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                코덥의 리얼-랭크 순위표는 <strong>코드업의 순위표와 동일하게 작동</strong>
                합니다.
                <br />
                <strong>풀이한 문제의 개수를 기준으로 순위를 제공</strong>
                합니다.
              </p>
              <p className="text-base md:text-lg leading-7 md:leading-8">
                그러나, <strong>2주 이상의 기간동안 '정확한 풀이'를 받지 못한 유저는 순위표에서 가려집니다.</strong>
                <br />
                리얼-랭크도 <strong>매일 한 번, 자정 이후에 갱신</strong>
                됩니다.
              </p> <br/>
              <p className="text-base md:text-lg leading-7 md:leading-8">
              리얼-랭크의 순위표의 아이디 색상은 <strong>레이팅 순위표의 아이디 색상과 일치합니다.</strong>
            </p>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-4">
            <AccordionTrigger className="text-xl font-bold">
              목차 4 - 기타 지표
            </AccordionTrigger>
            <AccordionContent>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                코덥에서는 기타 지표도 제공하고 있습니다.
                <br />
                현재 제공하는 기타 지표로는 <strong>최근 기록과 레이팅 추이</strong>가 있습니다.
                <br />
                <br />
                최근 기록에서는 <strong>전날 유저들의 기록</strong>을 보여줍니다.
                <br />
                전날 <strong>추가 레이팅 점수를 가장 많이 얻은 유저</strong>부터 보여드립니다.
                <br />
                <br />
                레이팅 추이에서는 <strong>유저들의 레이팅 변동을 계산</strong>하여
                <br />
                배치 레이팅 대비 <strong>레이팅을 가장 많이 올린 유저들</strong>을 보여드립니다.
              </p> 
              <p className="text-base md:text-lg leading-7 md:leading-8">
              기타 지표의 아이디 색상은 <strong>레이팅 순위표의 아이디 색상과 일치합니다.</strong>
            </p>
            </AccordionContent>
          </AccordionItem>

          <AccordionItem value="item-5">
            <AccordionTrigger className="text-xl font-bold">목차 5 - 프로필</AccordionTrigger>
            <AccordionContent>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                프로필에서는 <strong>레이팅 현황, 레이팅 기록, AI 프로필 분석, 맞춤형 문제 추천 기능을 제공</strong>합니다.
                <br />
                AI 프로필 분석 기능은 <strong>코드업 프로필과 최근 제출 기록을 기반으로 피드백을 제공</strong>해 줍니다.
                <br />
                실시간 갱신을 지원하지만, <strong>12시간에 한 번 갱신</strong>할 수 있습니다.
              </p>
              <p className="mb-6 text-base md:text-lg leading-7 md:leading-8">
                문제 추천 목록은 <strong>새로운 문제를 풀이하면 갱신</strong>됩니다.
                <br />
                갱신 시점은 <strong>자정 직후</strong>입니다.
                <br />
                일반적으로 <strong>12시 10분</strong>에 갱신이 완료됩니다.
              </p>
              <p className="text-base md:text-lg leading-7 md:leading-8">
                추천하는 문제는 <strong>'비슷한 실력대의 사람들은 풀었지만 나는 풀지 않은 문제'</strong>입니다.
                <br />
                <strong>숨어있는 꿀 문제나 시도해볼만한 문제를 추천</strong>해 줍니다.
                <br />
                기초 100제 문제들과 이벤트 문제들은 제외하고 추천합니다.
              </p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
}
