import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
} from "@/components/ui/pagination";
import { Skeleton } from "@/components/ui/skeleton";
import HorizontalAd from '@/components/ads/horizontalAd';


const SkeletonCard = () => {
  return (
    <TableRow>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
      <TableCell><Skeleton className="h-4 w-full" /></TableCell>
    </TableRow>
  );
};

export default function Rating() {
  const [ratings, setRatings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get("page")) || 1;
    if (page < 1 || page > 6) {
      navigate("/rating?page=1");
    } else {
      setCurrentPage(page);
      fetchRatings(page);
    }
  }, [location.search, navigate]);

  const fetchRatings = async (page) => {
    try {
      setLoading(true);
      const response = await fetch(`https://codup.kr/api/rating?page=${page}`);
      const data = await response.json();
      setRatings(data.ratings);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching ratings:", error);
      setLoading(false);
    }
  };

  return (
    <>
    <HorizontalAd />
    <div>
      <h1 className="text-4xl font-bold mb-4 mt-8 text-center">레이팅 순위표</h1>
      <div className="flex justify-center">
        <div className="w-full max-w-4xl">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>순위</TableHead>
                <TableHead>코드업 ID</TableHead>
                <TableHead>레이팅</TableHead>
                <TableHead>맞은문제 수</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                Array.from({ length: 10 }).map((_, index) => (
                  <SkeletonCard key={index} />
                ))
              ) : ratings.length > 0 ? (
                ratings.map((rating, index) => {
                  const globalIndex = index + 1 + (currentPage - 1) * 50;
                  return (
                    <TableRow key={rating.user_id}>
                      <TableCell>{globalIndex}</TableCell>
                      <TableCell>
                        <a
                          href={`/profile/${rating.user_id}`}
                          className={`font-extrabold ${
                            globalIndex <= 50
                              ? "text-red-500"
                              : globalIndex <= 100
                              ? "text-blue-500"
                              : globalIndex <= 150
                              ? "text-orange-500"
                              : "text-black"
                          }`}
                        >
                          {rating.user_id}
                        </a>
                      </TableCell>
                      <TableCell>{rating.rating}</TableCell>
                      <TableCell>{rating.ac_count}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan="4">표시할 데이터가 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="mt-4 flex justify-center mb-4">
        <Pagination>
          <PaginationContent>
            {Array.from({ length: 6 }, (_, index) => (
              <PaginationItem key={index}>
                <PaginationLink
                  href={`/rating?page=${index + 1}`}
                  isActive={currentPage === index + 1}
                >
                  {index + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
          </PaginationContent>
        </Pagination>
      </div>
    </div>
    </>
  );
}
