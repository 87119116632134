import { useState, useEffect, useRef } from 'react';
import { Button } from "@/components/ui/button";
import { SheetTrigger, SheetContent, Sheet } from "@/components/ui/sheet";
import { Input } from "@/components/ui/input";

export default function Header() {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSheetOpenChange = (open) => {
    setIsSheetOpen(open);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim() !== '') {
      window.location.href = `/profile/${searchTerm}`;
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <>
      {isSheetOpen && <div className="fixed inset-0 bg-black opacity-50 z-[1000]" />}
      <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-[1100]">
        <nav className="hidden flex-col gap-8 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 lg:gap-6">
          <a className="flex items-center gap-2 text-2xl font-semibold md:text-2xl whitespace-nowrap" href="/">
            <span className="text-3xl font-bold mb-1 bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">Codup</span>
            <span className="sr-only">Codup Service</span>
          </a>
          <a className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap mt-2 mb-2 text-xl" href="/rating">
            레이팅 순위
          </a>
          <a className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap mt-2 mb-2 text-xl px-1" href="/real-rank">
            리얼-랭크
          </a>
          <div className="relative" ref={dropdownRef}>
            <button
              className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap mt-2 mb-2 text-xl px-0 flex items-center"
              onClick={toggleDropdown}
            >
              기타 지표
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className={`ml-1 h-3 w-3 transition-transform duration-200 ${isDropdownOpen ? 'rotate-180' : ''}`}
                aria-hidden="true"
              >
                <path d="m6 9 6 6 6-6"></path>
              </svg>
            </button>
            {isDropdownOpen && (
              <div className="absolute left-0 top-full mt-2 w-48 bg-white shadow-md rounded-md z-10">
                <ul className="grid gap-3 p-4">
                  <li>
                    <a href="/status" className="text-muted-foreground hover:text-foreground transition-colors block">
                      최근 기록
                    </a>
                  </li>
                  <li>
                    <a href="/rating/climbers" className="text-muted-foreground hover:text-foreground transition-colors block">
                      레이팅 추이
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <a className="text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap mt-2 mb-2 text-xl px-0" href="/faq">
            설명서
          </a>
        </nav>

        <Sheet open={isSheetOpen} onOpenChange={handleSheetOpenChange}>
          <SheetTrigger asChild>
            <Button className="shrink-0 md:hidden" size="icon" variant="outline">
              <MenuIcon className="h-5 w-5" />
              <span className="sr-only">Toggle navigation menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="z-[1100] flex flex-col gap-8 text-lg font-medium">
            <nav>
              <a className="block text-3xl font-semibold md:text-3xl whitespace-nowrap mb-8" href="/">
                <span className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">Codup</span>
                <span className="sr-only">Codup Service</span>
              </a>
              <a className="block text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap text-2xl mt-4 ml-1" href="/rating">
                레이팅
              </a>
              <a className="block text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap text-2xl mt-4 ml-1" href="/real-rank">
                리얼-랭크
              </a>
              <a className="block text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap text-2xl mt-4 ml-1" href="/status">
                최근 기록
              </a>
              <a className="block text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap text-2xl mt-4 ml-1" href="/rating/climbers">
                레이팅 추이
              </a>
              <a className="block text-muted-foreground transition-colors hover:text-foreground whitespace-nowrap text-2xl mt-4 ml-1" href="/faq">
                설명서
              </a>
            </nav>
          </SheetContent>
        </Sheet>

        <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
          <form className="ml-auto flex-1 sm:flex-initial" onSubmit={handleSearch}>
            <div className="relative">
              <SearchIcon className="absolute left-2.5 top-3 h-4 w-4 text-muted-foreground" />
              <Input
                className="pl-8 sm:w-[300px] md:w-[200px] lg:w-[300px] text-center"
                placeholder="Search Codeup Handle"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </form>
        </div>
      </header>
    </>
  );
}

function MenuIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="4" x2="20" y1="12" y2="12"/>
      <line x1="4" x2="20" y1="6" y2="6"/>
      <line x1="4" x2="20" y1="18" y2="18"/>
    </svg>
  );
}

function SearchIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="m21 21-4.3-4.3" />
    </svg>
  );
}
