import React, { useEffect, useRef } from 'react';

const HorizontalAd = () => {
  const adRef = useRef(null);

  useEffect(() => {
    const pushAd = () => {
      try {
        if (window.adsbygoogle && adRef.current) {
          window.adsbygoogle.push({});
        }
      } catch (e) {
        console.error(e);
      }
    };

    const handleAdLoad = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        pushAd();
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(handleAdLoad, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (adRef.current) {
      observer.observe(adRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="ad-container mt-6" style={{ textAlign: 'center', width: '100%', height: '150px', position: 'relative' }}>
      <ins ref={adRef} className="adsbygoogle"
        style={{
          display: 'block',
          margin: '0 auto',
          height: '150px', // 고정된 높이를 설정
          width: '100%',
          position: 'relative',
        }}
        data-ad-client="ca-pub-7039912271608681"
        data-ad-slot="6968604462"
        data-ad-format="horizontal"
        data-full-width-responsive="true"></ins>
    </div>
  );
};

export default HorizontalAd;
