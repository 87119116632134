import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Skeleton } from "@/components/ui/skeleton";
import { useParams } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Switch } from "@/components/ui/switch";
import { Chart, registerables } from 'chart.js';
import { Terminal, AlertCircle, CalendarDays } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/avatar";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

import HorizontalAd from '@/components/ads/horizontalAd';

Chart.register(...registerables);

const SkeletonCard = () => (
  <div className="flex flex-col space-y-3">
    <Skeleton className="h-[125px] w-full rounded-xl" />
    <div className="space-y-2">
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-3/4" />
    </div>
  </div>
);

const AlertForNoStatus = () => (
  <Alert className="border border-blue-500 z-50">
    <Terminal className="h-4 w-4" />
    <AlertTitle>비활성 계정</AlertTitle>
    <AlertDescription>
      최근 2주동안 새로운 문제를 풀이하지 않았기에 표시할 기록이 없습니다.
    </AlertDescription>
  </Alert>
);

const AlertForWrongID = () => (
  <Alert variant="destructive">
    <AlertCircle className="h-4 w-4" />
    <AlertTitle>잘못된 입력</AlertTitle>
    <AlertDescription>
      잘못된 아이디 입력입니다.<br />새로운 계정이라면 1문제 이상 풀이하고 다음날에 방문해주세요.
    </AlertDescription>
  </Alert>
);

const easeInOutQuad = (t) => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

const getRandomDelay = () => {
  // Generate a random delay between 0.5 and 2 seconds
  return 500 + Math.random() * 1500;
};

const ProfileUpdateDialog = ({ userId, refreshTime }) => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');

  const handleUpdateClick = async () => {
    setLoading(true);
    setProgress(0); // Reset progress

    const startTime = Date.now();
    const duration = 25000; // 25 seconds
    let nextUpdateTime = Date.now() + getRandomDelay();

    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeElapsed = currentTime - startTime;
      let progress = timeElapsed / duration;

      if (progress < 1) {
        if (currentTime >= nextUpdateTime) {
          progress = easeInOutQuad(progress) * 100;
          setProgress(progress);
          nextUpdateTime = currentTime + getRandomDelay();
        }
      } else {
        setProgress(100);
        clearInterval(interval);
      }
    }, 100);

    try {
      const response = await axios.put(`https://codup.kr/api/profile_update/${userId}`);

      if (response.status === 201) {
        clearInterval(interval);
        setProgress(100); // Complete progress
        setTimeout(() => {
          window.location.reload();
        }, 300); // Reload after 0.3 seconds
      }

    } catch (error) {
      clearInterval(interval);
      setLoading(false);
      setError(error.response ? error.response.data.detail : '갱신에 실패하였습니다. 다시 시도해주세요.');
    }
  };

  const handleButtonClick = () => {
    const currentTime = new Date();
    if (currentTime < new Date(refreshTime)) {
      const timeDifference = new Date(refreshTime) - currentTime;
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      setUpdateMessage(`${hours}시간 ${minutes}분 이후에 갱신을 진행할 수 있습니다.`);
      setShowDialog(true);
    } else {
      handleUpdateClick();
      setShowDialog(true);
    }
  };

  return (
    <>
      <Button style={{ marginBottom: '0.4rem' }} onClick={handleButtonClick}>갱신 요청</Button>
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent className="max-w-lg mx-auto w-11/12 md:w-2/3 lg:w-1/2">
          <DialogHeader>
            <DialogTitle>AI 프로필 분석 업데이트</DialogTitle>
            <DialogDescription style={{ textAlign: 'center' }}>
              {loading ? (
                <>
                  프로필 갱신 작업을 진행합니다.<br />
                  컴퓨터 풀가동 중이에요! (๑•̀ㅂ•́)و✧
                </>
              ) : (
                updateMessage
              )}
            </DialogDescription>
          </DialogHeader>
          {loading && <Progress value={progress} className="w-full" />}
          {error && <Alert variant="destructive">{error}</Alert>}
        </DialogContent>
      </Dialog>
    </>
  );  
};

const CodeupProfileCard = ({ userId, ratings }) => {
  const isActiveUser = ratings && ratings.active_user === 1;

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <a href={`https://codeup.kr/userinfo.php?user=${userId}`} target="_blank" rel="noopener noreferrer">
          <Button variant="link" className="hidden sm:inline-block">
            @Codeup Profile
          </Button>
        </a>
      </HoverCardTrigger>
      <HoverCardContent className="w-80">
        <div className="flex justify-between space-x-4">
          <Avatar>
            <AvatarImage src="/codeup.png" />
            <AvatarFallback>CP</AvatarFallback>
          </Avatar>
          <div className="space-y-1">
            <h4 className="text-sm font-semibold">@Codeup {userId}</h4>
            <p className="text-sm">Click to view profile on Codeup.</p>
            <div className="flex items-center pt-2">
              <CalendarDays className="mr-2 h-4 w-4 opacity-70" />
              <span className="text-xs text-muted-foreground">
                {isActiveUser ? 'Active User' : 'Inactive User'}
              </span>
            </div>
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

const Profile = () => {
  const { userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userId) {
      axios.get(`https://codup.kr/api/profile/${userId}`)
        .then(response => {
          const profileData = response.data;

          // status 필드가 문자열인지 배열인지 확인하고 문자열인 경우 빈 배열로 대체
          if (typeof profileData.status === 'string') {
            profileData.status = [];
          }

          setProfile(profileData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching profile data:', error);
          if (error.response && error.response.status === 404) {
            setError('User not found');
          } else {
            setError('An error occurred');
          }
          setLoading(false);
        });
    } else {
      console.error('Invalid userId');
      setError('Invalid userId');
      setLoading(false);
    }
  }, [userId]);

  if (loading) {
    return (
      <div className="container mx-auto p-4">
        <SkeletonCard />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        {error === 'User not found' ? <AlertForWrongID /> : <div>{error}</div>}
      </div>
    );
  }

  if (!profile) {
    return <div>No profile data available or incomplete data.</div>;
  }

  const ratings = profile.ratings || {};
  const isActiveUser = ratings.active_user === 1;
  const percentileDisplay = ratings.percentile === 0 ? '0% (0.001% 이내)' : `상위 ${ratings.percentile}%`;

  const ratingData = (profile.status || []).map(status => ({
    date: status.status_date,
    rating: status.rating,
  })).reverse();

  const data = {
    labels: ratingData.map(d => d.date),
    datasets: [
      {
        label: 'Rating',
        data: ratingData.map(d => d.rating),
        borderColor: 'rgba(90, 128, 183, 1)',
        backgroundColor: 'rgba(90, 128, 183, 0.2)',
        fill: false,
        borderWidth: 4,
        pointRadius: 3,
        pointBackgroundColor: 'rgba(90, 128, 183, 1)',
      },
    ],
  };

  const yMin = Math.min(...ratingData.map(d => d.rating)) - 50;
  const yMax = Math.max(...ratingData.map(d => d.rating)) + 50;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: '레이팅 추이',
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        min: yMin < 0 ? 0 : yMin,
        max: yMax,
        ticks: {
          autoSkip: true,
          callback: value => value.toFixed(0),
        },
      },
    },
  };

  const formatComment = (comment) => comment.replace(/\n/g, '<br />');

  const getColorClass = (globalIndex, id) => {
    if (id === 'kimgihong38' || id === 'admin') return 'special-gradient';
    if (globalIndex <= 50) return "text-red-500";
    if (globalIndex <= 100) return "text-blue-500";
    if (globalIndex <= 150) return "text-orange-500";
    return "text-black";
  };

  return (
    <>
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-5 mt-2 flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div className="flex items-center">
          <span>
            Codeup
            <span className={`font-extrabold mx-2 ${getColorClass(ratings.rank, profile.user_id)}`}>
              {`@${profile.user_id}`}
            </span>
          </span>
        </div>
        <CodeupProfileCard userId={userId} ratings={ratings} />
      </h1>
      <hr className="my-4" />

      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>레이팅</CardTitle>
            <Switch id="active-user" checked={isActiveUser} readOnly />
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4 text-center">
            {[
              { title: "풀이한 문제 수", value: `${profile.ac_count}문제` },
              { title: "현재 순위", value: isActiveUser ? `${ratings.rank}등 (전체 ${ratings.total_recent_users}명)` : '비활성 계정' },
              { title: "백분위", value: isActiveUser ? percentileDisplay : '비활성 계정' },
              { title: "현재 레이팅", value: `${ratings.current}점` },
              { title: "배치 레이팅", value: `${ratings.initial}점` },
            ].map((item, index) => (
              <Card key={index}>
                <CardHeader>
                  <CardTitle>{item.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-lg">{item.value}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
      <hr className="my-4" />
      <HorizontalAd />

      <Card className="mb-2 mt-4" style={{ height: 'auto' }}>
  <CardHeader>
    <CardTitle>레이팅 기록</CardTitle>
  </CardHeader>
  <CardContent>
    {ratingData.length > 0 ? (
      <>
        <div style={{ height: '250px' }}>
          <Line data={data} options={options} height={250} />
        </div>
        <div className="mt-8" style={{ maxHeight: '350px', overflowY: 'auto' }}>
          <Table className="min-w-full table-auto">
            <TableHeader>
              <TableRow>
                <TableHead className="font-bold text-center">날짜</TableHead>
                <TableHead className="font-bold text-center" style={{ minWidth: '100px' }}>새로 풀이한 문제</TableHead>
                <TableHead className="font-bold text-center">획득 점수</TableHead>
                <TableHead className="font-bold text-center hidden md:table-cell">레이팅</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {profile.status.slice(0, 20).map((status) => (
                <TableRow key={status.status_date}>
                  <TableCell className="font-bold text-center">{status.status_date}</TableCell>
                  <TableCell className="font-bold text-center">{status.daily_ac_count}문제</TableCell>
                  <TableCell className="font-bold text-center">{status.additional_score}점</TableCell>
                  <TableCell className="font-bold text-center hidden md:table-cell">{status.rating}점</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </>
    ) : (
      <AlertForNoStatus />
    )}
  </CardContent>
</Card>

      <hr className="my-4" />
      <HorizontalAd />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <Card>
          <CardHeader className="flex items-center justify-between flex-row">
            <CardTitle>AI 프로필 분석</CardTitle>
            <ProfileUpdateDialog userId={userId} refreshTime={profile.profile_comment.refresh_time} />
          </CardHeader>
          <CardContent>
            {profile.profile_comment && profile.profile_comment.comment && profile.profile_comment.comment !== "None" ? (
              <>
                <p className="leading-relaxed mb-4" dangerouslySetInnerHTML={{ __html: formatComment(profile.profile_comment.comment) }}></p>
                <p className="leading-relaxed">
                  업데이트 가능 시점 : {
                    new Date() > new Date(profile.profile_comment.refresh_time) ? '갱신 가능' : profile.profile_comment.refresh_time
                  }
                </p>
              </>
            ) : (
              <>
                <p>아직 표시할 데이터가 없습니다.<br />갱신해주세요!</p><br/>
                <p className="leading-relaxed">업데이트 가능 시점 : 갱신 가능</p>
              </>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="mt-2 pt-1">맞춤형 문제 추천</CardTitle>
          </CardHeader>
          <CardContent>
            {profile.recommend_problems && profile.recommend_problems.length > 0 ? (
              profile.recommend_problems.map((problem, index) => (
                <Card key={index} className="mb-4">
                  <CardHeader>
                    <CardTitle className="flex items-center problem-title">
                      <span className="mr-2 text-2xl font-bold">#{index + 1}</span>
                      <a
                        href={`https://codeup.kr/problem.php?id=${problem}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl font-bold text-blue-500 hover:underline"
                      >
                        {problem}
                      </a>
                    </CardTitle>
                  </CardHeader>
                </Card>
              ))
            ) : (
              <p>No recommended problems available.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
    </>
  );
};

export default Profile;
